import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text mdxType="Text">
  Hola.
    </Text>
    <p>{`Carlos Iriarte - also known as Leo - this is my site.`}</p>
    <p>{`I like learning. Let's talk about space 🚀, math ∞, mythology 🦄 🐉 👻 🔮, philosophy 🤔 and history 📚.`}</p>
    <p>{`I want to keep growing as a person, if you have any feedback about my writings feel free to drop a message!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      